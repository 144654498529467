import booleanContains from '@turf/boolean-contains'
import type { FeatureCollection } from 'geojson'
import { useMemo } from 'react'
import { Layer, LayerProps, Source } from 'react-map-gl'
import { aDrawnFeature, aSelectedListing, useAtomValue } from '~/atoms'
import { MapView, useCurrentMapView } from '~/hooks'
import { Listing } from '~/models'
import { CLUSTER_ZOOM_LEVEL, PROPERTY_TYPES_COLORS } from '~/utils'

export default function MapPropertyClustersLayer({
  properties,
}: {
  properties: Listing[]
}) {
  const currentMapView = useCurrentMapView()
  const selectedListing = useAtomValue(aSelectedListing)
  const drawnFeature = useAtomValue(aDrawnFeature)

  const selectedListingPoint = useMemo(() => {
    if (!selectedListing) {
      return undefined
    }

    return {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            ...selectedListing.location,
            type: 'Point',
          },
          properties: {
            ...selectedListing,
          },
        },
      ],
    } as FeatureCollection
  }, [selectedListing])

  const points: FeatureCollection = useMemo(() => {
    let features = properties.filter((l: any) => l.location)
    if (drawnFeature) {
      features = features.filter((l: any) =>
        booleanContains(drawnFeature, l.location)
      )
    }

    return {
      type: 'FeatureCollection',
      features: features.map((l: any) => {
        return {
          type: 'Feature',
          geometry: {
            ...l.location,
            type: 'Point',
          },
          properties: {
            _id: l._id,
            slug: l.slug,
            status: l.status,
            address: l.property
              ? l.property.address2
                ? `${l.property.address} ${l.property.address2}`
                : l.property.address
              : null,
            type: l.type,
            imageType: l.type === 'saleLease' ? 'sale-lease' : l.type,
            name: l.name,
            lng: l.location.coordinates[0],
            lat: l.location.coordinates[1],
            primaryPropertyType:
              l.propertyTypes.length > 0 ? l.propertyTypes[0] : 'Other',
            propertyTypes: l.propertyTypes.join(', '),
          },
        }
      }),
    }
  }, [properties, drawnFeature])

  const clusterCountLayer: LayerProps = {
    id: 'property-clusters-count',
    type: 'symbol',
    filter: ['has', 'point_count'],
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['Roboto Medium'],
      'text-size': 12,
      'text-offset': [0, 0.15],
    },
    paint: {
      'text-color': '#ffffff',
    },
  }

  const clusterLayer: LayerProps = {
    type: 'circle',
    id: 'property-clusters',
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': '#7c3aed',
      'circle-radius': 18,
      'circle-stroke-color': '#7c3aed',
      'circle-stroke-opacity': 0.25,
      'circle-stroke-width': 5,
    },
  }

  const layerCaseStyles = useMemo(() => {
    const cases: any = []
    const types = ['lease', 'sale', 'sale-lease']
    PROPERTY_TYPES_COLORS.forEach(({ value, propertyType }) => {
      types.forEach((type) => {
        cases.push(
          ...[
            [
              'all',
              ['==', ['get', 'imageType'], type],
              ['==', ['get', 'primaryPropertyType'], propertyType],
              // ['in', propertyType, ['get', 'propertyTypes']],
            ],
            `${value}-${type}`,
          ]
        )
      })
    })
    return cases
  }, [])

  const unclusteredPointLayer: LayerProps = {
    id: 'property-single-clusters',
    type: 'symbol',
    filter: ['!', ['has', 'point_count']],
    layout: {
      // @ts-ignore
      'icon-image': [
        'case',
        ...layerCaseStyles,

        // default
        'red-sale-lease',
      ],
      'icon-size': ['match', ['get', 'type'], 'lease', 0.085, 0.085],
      'icon-allow-overlap': true,
    },
  }

  const selectedPoint: LayerProps = {
    id: 'selected-pointer-layer',
    type: 'symbol',
    layout: {
      'icon-image': [
        'match',
        ['get', 'type'],
        'sale',
        'selected-sale',
        'lease',
        'selected-lease',
        'selected-sale-lease',
      ],
      'icon-size': [
        'match',
        ['get', 'type'],
        'lease',
        0.085 + 0.05,
        0.085 + 0.05,
      ],
      'icon-allow-overlap': true,
    },
  }

  if (currentMapView !== MapView.Map) {
    return null
  }

  return (
    <>
      <Source
        id="property-listings"
        type="geojson"
        data={points}
        cluster={true}
        clusterMaxZoom={CLUSTER_ZOOM_LEVEL}>
        <Layer {...clusterLayer} />
        <Layer {...clusterCountLayer} />
        <Layer {...unclusteredPointLayer} />
      </Source>
      {selectedListing && (
        <Source type="geojson" data={selectedListingPoint}>
          <Layer {...selectedPoint} />
        </Source>
      )}
    </>
  )
}
